<template>
  <router-view />
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { getSearchTypeByLabel, SEARCH_LABELS } from '@/constants/search';

export default {
  name: 'Faq',

  data() {
    return {
      lastSearchType: null,
    };
  },

  computed: {
    ...mapState('search', ['type']),
  },

  created() {
    this.lastSearchType = this.type;
    // TODO: выставлять тип поиска для страниц "мои заявки", "мои услуги"
    this.setType(getSearchTypeByLabel(SEARCH_LABELS.faq).id);
  },

  beforeRouteLeave(to, from, next) {
    if (to.name !== 'Search') {
      this.setType(this.lastSearchType);
    }
    this.clearBreadcrumbs();
    next();
  },

  methods: {
    ...mapActions('search', ['setType']),
    ...mapActions('breadcrumbs', ['clearBreadcrumbs']),
  },
};
</script>
